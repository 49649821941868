// JsFromRoutes CacheKey d30304193233014a5cdf349348824349
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/api/experiments(/:prefix)'),
  update: /* #__PURE__ */ definePathHelper(
    'put',
    '/api/experiments/:experiment_name/:variant',
  ),
  evaluate: /* #__PURE__ */ definePathHelper(
    'get',
    '/api/experiments/:experiment_name/evaluate',
  ),
}
