<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
</script>

<div class="bg-[#f2f1ef]">
  <Section center="true" verticalPadding="short">
    <div class="md:max-w-[740px] lg:max-w-none mx-auto">
      <div class="xs:flex flex-row text-left">
        <div class="sm:w-1/3 xs:text-left xs:px-2.5 mt-2.5">
          <p class="text-lg uppercase text-[#857550]">simple & easy</p>
          <h2 class="text-4xl">How It Works</h2>
        </div>
        <div class="sm:w-9/12 mt-5 sm:mt-0 sm:ml-2 xs:px-2.5 mb-2.5">
          <div class="mb-4">
            <p>
              With FIXD, you don't need to know anything about cars to know
              what's going on with your car – just plug it in and start
              scanning. FIXD inspects every inch of your vehicle to expose any
              current or potential issues.
            </p>
          </div>

          <p>
            The sensor connects via Bluetooth with the free FIXD App on your
            phone, so the results are sent straight to the palm of your hand as
            soon as the scan is complete. And the best part is that everything
            is explained in simple terms, so you’re never left scratching your
            head asking, “What does that mean?!”
          </p>
        </div>
      </div>
    </div>
  </Section>
</div>
