<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Banner from '@/components/landing-page/Banner.svelte'
  import HeroImage from '@/images/landing-page/scorpio/hot-new-car-gadget-hero.jpg'
  import { onMount } from 'svelte'

  import BusinessInsiderImage from '@/images/landing-page/scorpio/business-insider.png'
  import ForbesImage from '@/images/landing-page/forbes-simple.png'
  import CarAndDriverImage from '@/images/landing-page/scorpio/car-and-driver-color.png'
  import MashableImage from '@/images/landing-page/scorpio/mashable-round.png'
  import FoxImage from '@/images/landing-page/scorpio/fox-color.png'
  import TranscodeVideoOne from '@/images/landing-page/scorpio/transcode.mp4'
  import TranscodeVideoTwo from '@/images/landing-page/scorpio/transcode-2.mp4'
  import TranscodeVideoThree from '@/images/landing-page/scorpio/mechanic-hotline-transcode.mp4'
  import BuyBoxV2 from '@/components/landing-page/widgets/BuyBoxV2.svelte'

  import FavoriteCarImage from '@/images/landing-page/scorpio/favorite_car_scanner.jpg'
  import Footer from '@/components/landing-page/Footer.svelte'

  import { FaStarFilledRound } from '@/components/icons'

  export let funnel
  export let gateway

  import CompatibilityWidget from '@/components/landing-page/widgets/CompatibilityWidget.svelte'

  $: checkoutLink = '#buy_box'

  onMount(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        if (this.getAttribute('href').includes('#')) {
          e.preventDefault()

          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
          })
        }
      })
    })
  })

  const date = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  $: firstVideo = null
  $: firstVideoPaused = false

  $: secondVideo = null
  $: secondVideoPaused = null

  $: thirdVideo = null
  $: thirdVideoPaused = null

  const toggleVideo = (video) => {
    const playing = isVideoPlaying(video)

    playing ? video.pause() : video.play()
  }

  const isVideoPlaying = (video) => {
    return !!(
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    )
  }

  const reviews = [
    {
      description:
        'FIXD saved me hundreds of dollars! I ordered another one for my mother’s car.',
      date: 'Feb. 4, 2024',
      count: '31',
    },
    {
      description:
        'Takes the guesswork out of car troubleshooting with easy-to-understand descriptions and fixes.',
      date: 'May 31, 2024',
      count: '43',
    },
    {
      description:
        'This is a gem of an auto tool. Everyone should have one of these.',
      date: 'Jan. 23, 2023',
      count: '19',
    },
  ]
</script>

<style>
  .play-button {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 20px solid #fff;
    border-bottom: 15px solid transparent;
  }
</style>

<main class="font-montserrat text-black">
  <div class="shadow-lg hidden md:block">
    <Banner darkTheme={false} buyUrl={checkoutLink} />
  </div>
  <div class="shadow-lg block md:hidden">
    <Banner darkTheme={false} showMenu={true} />
  </div>

  <div class="max-w-screen-md lg:max-w-[940px] mx-auto pt-[2%]">
    <div class="bg-[#fff2cc] px-[3%] py-[2%]">
      <p class="text-15">
        <span class="font-bold">UPDATE</span>- The
        <span class="strikethrough font-bold">47%</span>
        <a
          href={checkoutLink}
          class="text-blue-600 hover:cursor-pointer font-bold">67% OFF</a
        > sale is still going!
      </p>
    </div>
  </div>

  <Section verticalPadding="short" horizontalPadding="none">
    <div class="px-[1%] md:px-[5%]">
      <div class="max-w-screen-md lg:max-w-[940px] mx-auto">
        <header class="text-center mt-5 mb-7">
          <h1
            class="text-2xl xs:text-4xl font-bold xs:font-semibold mb-5 leading-tight"
          >
            Avoid These 3 Expensive Car Maintenance <br
              class="hidden lg:block"
            />
            Mistakes with FIXD
          </h1>

          <h2 class="text-lg xs:text-2xl font-normal xs:font-semibold">
            Find out how FIXD saves you from costly errors that could set you
            back $1000s!
          </h2>
        </header>

        <article>
          <div class="px-2 mb-5">
            <p class="text-sm">{date}</p>
          </div>

          <div class="mx-auto w-full xs:w-3/4">
            <img
              src={HeroImage}
              alt="100% money-back guarantee"
              class="rounded-3xl"
            />
          </div>
          <div class="py-[10%] my-2 xs:py-0 text-center">
            <h3 class="text-2xl font-medium">
              Over 40,000 ⭐⭐⭐⭐⭐ 5-Star Reviews and Counting
            </h3>
          </div>
        </article>

        <article>
          <div
            class="grid grid-row-2 grid-cols-3 xs:grid-cols-5 gap-4 items-center justify-center py-[5%]"
          >
            <div class="text-center">
              <img
                src={BusinessInsiderImage}
                alt="business insider"
                class="w-[90%]"
              />
            </div>
            <div class="text-center">
              <img
                src={CarAndDriverImage}
                alt="business insider"
                class="w-[90%]"
              />
            </div>
            <div class="text-center">
              <img src={ForbesImage} alt="business insider" class="w-4/5" />
            </div>
            <div class="text-center">
              <img src={MashableImage} alt="business insider" class="w-4/5" />
            </div>
            <div class="text-center">
              <img src={FoxImage} alt="business insider" class="w-1/2" />
            </div>
          </div>
        </article>

        <article class="space-y-4 text-15 mt-2.5 mb-5">
          <p>
            <span class="font-bold">Owning a car is expensive.</span> Car
            payments, maintenance prices, and repair costs are going up every
            year. But thanks to
            <a href={checkoutLink} class="text-black font-bold">FIXD</a> more
            than 2 million drivers like you are saving
            <span class="italic">$1000s</span>
            by avoiding these common <span class="italic">(but costly!)</span> maintenance
            mistakes:
          </p>
        </article>

        <article class="space-y-4 pb-[5%] px-2.5 md:px-0">
          <div
            class="flex gap-2 md:gap-5 items-center justify-center flex-col-reverse md:flex-row mb-14 md:mb-0"
          >
            <div class="flex-1 basis-1/2">
              <div class="my-2.5">
                <h3 class="text-center">
                  <span class="font-medium">1.</span> Ignoring the Check Engine Light
                </h3>
              </div>
              <p class="text-15">
                When your check engine light’s on, the problem could cost you
                $50 or $5,000 - you won’t know until you take your car to the
                mechanic and pay a <span class="font-bold"
                  >$300 diagnostic fee.</span
                > That doesn't even include fixing the issue!
              </p>
              <p class="text-15 mt-5">
                Or you can just ignore it, but that could lead to bigger, more
                expensive issues down the road if you don't know what's actually
                wrong.
              </p>
              <div
                class="bg-[#d2e6c8] rounded-lg px-2.5 pb-5 pt-3 border-green border mt-2.5"
              >
                <p class="text-lg font-bold text-center mb-2">
                  How to avoid this mistake with FIXD:
                </p>
                <p class="text-15 font-medium">
                  FIXD decodes check engine lights for you in seconds - without
                  a $300 diagnostic fee from your mechanic. All you need to do
                  is:
                </p>
                <div
                  class="rounded-lg bg-white p-2.5 text-15 font-medium mx-2.5 mt-3 shadow-lg"
                >
                  <p>1. Plug in the FIXD sensor</p>
                  <p>2. Connect your smartphone app</p>
                  <p>3. Tap to scan!</p>
                </div>

                <div class="mt-2.5 flex justify-center">
                  <a
                    href={checkoutLink}
                    class="text-15 font-semibold text-white bg-green rounded-full px-4 py-2"
                    >Click to get <span class="text-red strikethrough">47%</span
                    >
                    <span class="font-bold">67% off</span> FIXD</a
                  >
                </div>
              </div>
            </div>
            <div class="flex-1 basis-1/2">
              <div class="relative">
                <video
                  bind:this={firstVideo}
                  bind:paused={firstVideoPaused}
                  src={TranscodeVideoOne}
                  alt="transcode"
                  autoplay
                  loop
                  muted
                  type="video/mp4"
                  class="rounded-2xl h-[500px] object-cover"
                  id="first_video"
                />
                <div class="absolute bottom-4 right-4">
                  <div
                    role="button"
                    tabindex={-1}
                    class="rounded-full bg-black h-11 w-11 flex items-center justify-center"
                    on:click={() => {
                      toggleVideo(firstVideo)
                    }}
                    on:keypress={() => {
                      toggleVideo(firstVideo)
                    }}
                  >
                    {#if firstVideoPaused}
                      <div class="ml-1.5 play-button" />
                    {:else}
                      <div
                        class="flex gap-1.5 h-full w-full items-center justify-center"
                      >
                        <div class="h-3/5 w-2 bg-white rounded-sm" />
                        <div class="h-3/5 w-2 bg-white rounded-sm" />
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex gap-5 flex-col md:flex-row items-center justify-center"
          >
            <div class="flex-1 basis-1/2">
              <div class="relative">
                <video
                  bind:this={secondVideo}
                  bind:paused={secondVideoPaused}
                  src={TranscodeVideoTwo}
                  alt="transcode"
                  autoplay
                  loop
                  muted
                  type="video/mp4"
                  class="rounded-2xl h-[500px] object-cover"
                />
                <div class="absolute bottom-4 right-4">
                  <div
                    role="button"
                    tabindex={-1}
                    class="rounded-full bg-black h-11 w-11 flex items-center justify-center"
                    on:click={() => {
                      toggleVideo(secondVideo)
                    }}
                    on:keypress={() => {
                      toggleVideo(secondVideo)
                    }}
                  >
                    {#if secondVideoPaused}
                      <div class="ml-1.5 play-button" />
                    {:else}
                      <div
                        class="flex gap-1.5 h-full w-full items-center justify-center"
                      >
                        <div class="h-3/5 w-2 bg-white rounded-sm" />
                        <div class="h-3/5 w-2 bg-white rounded-sm" />
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-1 basis-1/2">
              <div class="my-2 5">
                <h3 class="text-center">
                  <span class="font-medium">2.</span> Trusting your mechanic’s word
                  on maintenance
                </h3>
              </div>
              <div class="space-y-4 text-15">
                <p>
                  Mechanics are trained to upsell customers on high-priced - and
                  sometimes unnecessary maintenance services.
                </p>
                <p>
                  How do you know whether a maintenance recommendation is legit
                  or “fluff?”
                </p>
                <p>
                  Most people don't. That's why hard-working drivers like you
                  end up paying <span class="italic"
                    >$1000s too much every year</span
                  >
                  for services they don't really need!
                </p>
              </div>
              <div
                class="bg-[#d2e6c8] rounded-lg px-2.5 pb-5 pt-3 border-green border mt-2.5"
              >
                <p class="text-lg font-bold text-center mb-2">
                  How to avoid this mistake with FIXD:
                </p>
                <div class="text-15 font-medium">
                  <p class="mb-4">
                    FIXD tells you which maintenance services are actually
                    needed - and how much they should cost.
                  </p>
                  <p>
                    You get <span class="font-bold"
                      >confirmed price estimates</span
                    >
                    right in your smartphone app - so you never have to trust your
                    mechanic’s word or worry about getting overcharged again.
                  </p>
                </div>

                <div class="mt-2.5 flex justify-center">
                  <a
                    href={checkoutLink}
                    class="text-15 font-semibold text-white bg-green rounded-full px-4 py-2"
                    >Click to get <span class="text-red strikethrough">47%</span
                    >
                    <span class="font-bold">67% off</span> FIXD</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex md:gap-5 flex-col-reverse md:flex-row items-center justify-center"
          >
            <div class="flex-1 basis-1/2">
              <div class="my-2 5">
                <h3 class="text-center">
                  <span class="font-medium">3.</span> Not getting a second opinion
                </h3>
              </div>
              <div class="space-y-4 text-15">
                <p>
                  How do you know whether your mechanic is giving you the right
                  answer? They could be bending the truth, or they could just
                  not know any better.
                </p>
                <p>
                  But getting a second opinion usually means driving to another
                  mechanic, who might not be any more honest.
                </p>
                <p>That’s why most people never get a second opinion.</p>
              </div>
              <div
                class="bg-[#d2e6c8] rounded-lg px-2.5 pb-5 pt-3 border-green border mt-2.5"
              >
                <p class="text-lg font-bold text-center mb-2">
                  How to avoid this mistake with FIXD:
                </p>
                <div class="text-15 font-medium space-y-4">
                  <p>
                    With FIXD, you get unlimited access to our team of certified
                    on-call mechanics who are there to help - not to take your
                    money.
                  </p>
                  <p>
                    They're waiting to offer unbiased advice, answer repair and
                    maintenance questions, review estimates from your local
                    mechanic, and much more…all at no additional cost.
                  </p>
                  <p>
                    You can easily get a “second opinion” whenever you need!
                  </p>
                </div>

                <div class="mt-2.5 flex justify-center">
                  <a
                    href={checkoutLink}
                    class="text-15 font-semibold text-white bg-green rounded-full px-4 py-2"
                    >Click to get <span class="text-red strikethrough">47%</span
                    >
                    <span class="font-bold">67% off</span> FIXD</a
                  >
                </div>
              </div>
            </div>
            <div class="flex-1 basis-1/2">
              <div class="relative">
                <video
                  bind:this={thirdVideo}
                  bind:paused={thirdVideoPaused}
                  src={TranscodeVideoThree}
                  alt="transcode"
                  autoplay
                  loop
                  muted
                  type="video/mp4"
                  class="rounded-2xl h-[500px] object-cover"
                />
                <div class="absolute bottom-4 right-4">
                  <div
                    role="button"
                    tabindex={-1}
                    class="rounded-full bg-black h-11 w-11 flex items-center justify-center"
                    on:click={() => {
                      toggleVideo(thirdVideo)
                    }}
                    on:keypress={() => {
                      toggleVideo(thirdVideo)
                    }}
                  >
                    {#if thirdVideoPaused}
                      <div class="ml-1.5 play-button" />
                    {:else}
                      <div
                        class="flex gap-1.5 h-full w-full items-center justify-center"
                      >
                        <div class="h-3/5 w-2 bg-white rounded-sm" />
                        <div class="h-3/5 w-2 bg-white rounded-sm" />
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article class="mt-16 mb-10 px-2.5 md:px-0">
          <h2
            class="text-lg md:text-34 font-bold md:font-medium text-center mb-2.5"
          >
            Will FIXD work on your car?
          </h2>
          <CompatibilityWidget />
        </article>
      </div>
    </div>
  </Section>

  <div class="bg-[#f0f0f0] pt-7 pb-16">
    <div class="max-w-screen-md lg:max-w-[940px] mx-auto px-2.5">
      <h2 class="text-center mb-7">Real reviews from real customers</h2>
      <div class="flex flex-col md:flex-row space-y-5 md:space-y-0">
        {#each reviews as review}
          <div class="basis-1/3 bg-white rounded-2xl p-5 shadow-md text-center">
            <div class="inline-flex items-center mb-2.5">
              {#each new Array(5) as _}
                <FaStarFilledRound color="yellow" size="md" />
              {/each}
              <p class="text-lg ml-1">5 Stars</p>
            </div>
            <div class="text-15 space-y-6">
              <p>"{review.description}"</p>
              <div>
                <p class="text-green font-bold">Verified Purchase</p>
                <p>{review.date}</p>
              </div>
              <div class="w-3/4 mx-auto">
                <p class="italic">
                  {review.count} people found this helpful.
                </p>
              </div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>

  <Section verticalPadding="short" horizontalPadding="none">
    <div class="max-w-screen-md lg:max-w-[940px] mx-auto">
      <article>
        <header class="text-center">
          <div class="my-2 5">
            <h2>Why FIXD is America’s favorite car scanner:</h2>
          </div>
          <img
            class="rounded-xl w-full md:w-1/2"
            src={FavoriteCarImage}
            alt="fixd vs pro scanner"
          />
        </header>
        <div class="px-2.5 md:px-0">
          <div class="space-y-5 text-15 mb-9 pt-[5%]">
            <h2 class="text-2xl md:text-28">
              Why is FIXD the Top Car Tech Device of 2024?
            </h2>
            <p>There’s a very good reason why FIXD is becoming so popular.</p>
            <p>
              The big-name sites are calling FIXD <span class="italic"
                >"the year's hottest car gadget"</span
              >... and at <span class="font-bold">under $20</span>, it's no
              wonder drivers are snapping it up!
            </p>
            <p>
              Since being picked up by major news outlets, FIXD's popularity has
              exploded - we've sold <span class="font-bold"
                >more than 3 million sensors</span
              >
              in the U.S. and Canada, with more than
              <span class="font-bold">40,000 ⭐⭐⭐⭐⭐ 5-star ratings.</span>
            </p>
            <p>
              By the end of 2024, FIXD is set to become <span class="font-bold"
                >America's #1 best-selling car care scanner</span
              >. Even pro mechanics are ditching their expensive scan tools for
              this little gem.
            </p>
          </div>
          <div class="space-y-4 text-15 mb-10">
            <p>
              And FIXD is <span class="font-bold">free to use forever</span> once
              you own the device. With FIXD Premium, (about $8 a month, billed yearly)
              you'll unlock a wealth of benefits to save you even more time, money,
              and frustration as a car owner.
            </p>
            <p>
              It's <span class="font-bold"
                >better than your mechanic's $2,000 pro scanner</span
              >, and at a tiny fraction of the cost. Get FIXD today at
              <span class="font-bold">47% 67% off</span>, and you a
              <span class="font-bold">no-commitment 14-day free trial</span> to FIXD
              Premium so you can try out the benefits for yourself:
            </p>
            <div class="pl-2.5 pr-2.5 md:pl-24 md:pr-16">
              <ul>
                <li>
                  <span class="font-bold"
                    >• Know exactly what you should pay</span
                  >
                  for repairs and maintenance before you head to the shop -
                  <span class="italic">FIXD tells you right in the app!</span>
                </li>
                <li>
                  <span class="font-bold">• Find a mechanic you can trust</span>
                  - FIXD's network of repair shops are hand-picked for honesty, integrity,
                  and top-quality work.
                </li>
                <li>
                  <span class="font-bold">• Skip car care guesswork</span> - FIXD’s
                  team of expert mechanics is on call to answer questions, discuss
                  shop estimates, and help you diagnose problems.
                </li>
                <li class="font-bold italic">• And many more!</li>
              </ul>
            </div>
            <p>
              While you're at it, be sure to pick up a FIXD sensor for the
              parents and kids, too - if there are any issues with their cars,
              you can see right from your phone.
            </p>
            <p>
              They'll love FIXD, and you'll love <span class="font-bold"
                >the peace of mind of knowing they're safe.</span
              >
            </p>
            <p>
              FIXD even comes with a <span class="font-bold"
                >FREE 1-year warranty</span
              >
              and a <span class="font-bold">100% money-back guarantee</span>, so
              there's no risk to you. You can always return it for a prompt,
              full refund.
            </p>
          </div>
        </div>
      </article>

      <article class="mt-[5%] mb-[3%] px-2.5">
        <div class="text-center border-2 border-black-1 text-black-0">
          <header class="bg-green py-[1%]">
            <h2 class="xs:text-white font-black">Limited Time Savings</h2>
          </header>
          <div class="bg-yellow-200 space-y-4 text-15 px-2.5 pt-2.5 pb-5">
            <p>
              Right now, drivers everywhere are snapping up our <span
                class="strikethrough">47%</span
              >
              67% off deal - you can purchase FIXD and claim the discount
              <a href={checkoutLink} class="text-green underline font-bold"
                >here.</a
              >
            </p>

            <p>
              <span class="italic">Have more than one car?</span> Your whole family
              deserves the safety and peace of mind of FIXD. Pick up a multi-pack
              and monitor all of your family's cars from a single app!
            </p>
          </div>
        </div>

        <article class="w-full md:w-[80%] mx-auto text-black-1 pt-[3%]">
          <div id="buy_box" class="pt-[5%] pb-10 px-2.5">
            <BuyBoxV2 {funnel} {gateway} />
          </div>
        </article>

        <article class="space-y-5 text-15 text-center pb-[10%] pt-[3%]">
          <h2 class="text-30 font-normal">FAQ</h2>
          <div>
            <p class="font-bold">Does FIXD work on my car?</p>
            <p>
              <a href={checkoutLink} class="text-black font-bold">[FIXD]</a> works
              with all gas-powered cars, trucks, and SUVS made after 1995, and all
              passenger diesel vehicles made after 2007. It can read over 7,000 check
              engine codes, as well as ABS and brakes, airbag, battery, wheels and
              tires, and many more.
            </p>
          </div>
          <div>
            <p class="font-bold">
              Can it really tell me what's wrong with my car?
            </p>
            <p>
              Yes.<a href={checkoutLink} class="text-black font-bold">[FIXD]</a>
              gives you explanations of more than 7,000 check engine light codes,
              in simple terms everyone can understand.
            </p>
          </div>
          <div>
            <p class="font-bold">
              Is it available on Apple and Android phones?
            </p>
            <p>
              Yes. The free <a href={checkoutLink} class="text-black font-bold"
                >[FIXD]</a
              > app is compatible with both iPhones and Android smartphones.
            </p>
          </div>
          <div>
            <p class="font-bold">Is there any advantage to ordering now?</p>
            <p>
              Yes!
              <a href={checkoutLink} class="font-bold text-black">FIXD</a> is
              offering a deep discount right now to make it affordable to get
              FIXD for yourself and your loved ones. Right now, you can get FIXD
              at
              <span class="text-red font-bold strikethrough">47%</span>
              <span class="text-green font-bold">67%</span>
              <span class="font-bold">off</span>. This sale won’t last much
              longer, though.
            </p>
          </div>
        </article>
      </article>
    </div>
  </Section>

  <Footer />
</main>
