<script>
  import Benefits from '@/components/landing-page/Benefits.svelte'
  import Compatibility from '@/components/landing-page/Compatibility.svelte'
  import AsSeenOn from '@/components/landing-page/AsSeenOn.svelte'
  import OtherOBD2 from '@/components/landing-page/OtherOBD2.svelte'
  import TryRiskFree from '@/components/landing-page/TryRiskFree.svelte'
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'
  import Mission from '@/components/landing-page/Mission.svelte'
  import FAQ from '@/components/landing-page/FAQ.svelte'
  import About from '@/components/landing-page/About.svelte'
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import TopAdvertorialBanner from '@/components/landing-page/TopAdvertorialBanner.svelte'

  import BuyBox from '@/pages//scorpio/components/L007/BuyBoxL007.svelte'
  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  import Money from '@/util/money.mjs'

  export let funnel
  export let gateway

  const defaultOffer = funnel.initial_offers.find(
    (offer) => offer?.slug === funnel?.default_initial_offer_slug,
  )

  const buyUrl = `/buy/${funnel?.slug}` || '/buy/sensor67'
  const discountPercentage = 63

  const price = defaultOffer?.subtotal_price
    ? new Money(defaultOffer?.subtotal_price)
    : new Money({ amount: 21.99, currency: 'USD' })
</script>

<main class="md:pb-0">
  <TopAdvertorialBanner {discountPercentage} {buyUrl} />

  <Section center="true" verticalPadding="none">
    <div class="pt-5">
      <div class="block md:hidden">
        <div class="font-inter mt-[-8px]">
          <h1 class="text-lg sm:text-4xl title bold" id="bannerHeader">
            Decode check engine lights instantly
          </h1>
        </div>
      </div>
    </div>
  </Section>

  <div class="max-w-[1440px] mx-auto">
    <BuyBox {funnel} {gateway} />
  </div>

  <div id="asSeenOn">
    <AsSeenOn />
  </div>

  <Benefits />

  <OtherOBD2 {price}>
    <JumpToBuyBoxButton slot="cta">
      <span class="uppercase">Get FIXD NOW {discountPercentage}% OFF</span>
    </JumpToBuyBoxButton>
  </OtherOBD2>

  <Compatibility>
    <JumpToBuyBoxButton slot="cta" maxWidth={56}>
      <span class="uppercase">BUY FIXD NOW</span>
    </JumpToBuyBoxButton>
  </Compatibility>

  <LiveReviews />

  <TryRiskFree percentage={discountPercentage}>
    <JumpToBuyBoxButton slot="cta" size="medium">
      <span class="uppercase">Get FIXD NOW {discountPercentage}% OFF</span>
    </JumpToBuyBoxButton>
  </TryRiskFree>

  <Mission />

  <FAQ />

  <About />

  <Footer />
</main>
