<script>
  export let buyUrl
  export let discountPercentage = 67

  import {
    Lightning,
    SalesTag,
    ShippingTruck,
    Certificate,
  } from '@/components/icons'

  import Banner from '@/components/landing-page/Banner.svelte'
</script>

<div class="hidden md:block">
  <div class="banner bg-green py-2.5">
    <div class="flex justify-evenly w-100 text-white font-bold font-inter">
      <div class="flex items-center">
        <div class="mr-3">
          <SalesTag color="white" />
        </div>
        <p class="text-xl">Get {discountPercentage}% off!</p>
      </div>
      <div class="flex basis-1/3 border-x justify-center item-center">
        <div class="mr-3">
          <ShippingTruck color="white" />
        </div>
        <p class="text-xl">Free Shipping</p>
      </div>
      <div class="flex">
        <div class="mr-3">
          <Certificate color="white" />
        </div>
        <p class="text-xl">Free 1 Year Warranty</p>
      </div>
    </div>
  </div>
  <Banner {buyUrl} />
</div>

<div class="block md:hidden">
  <div class="flex font-inter items-center leading-tight">
    <div class="bg-yellow py-1 px-2 h-8">
      <p class="uppercase font-bold text-10 text-center">
        Lightning <br /> deal
      </p>
    </div>
    <div class="bg-green flex gap-2 items-center pl-1.5 h-8 w-full">
      <Lightning color="white" size="sm" />
      <p class="text-white my-0 text-center font-semibold text-xs">
        {discountPercentage}% OFF + FREE SHIPPING + FREE WARRANTY
      </p>
    </div>
  </div>
  <Banner
    showMenu={true}
    height="small"
    fillShoppingCardIcon={false}
    {buyUrl}
  />
</div>
