// JsFromRoutes CacheKey f92dde9f28fa2783587b2e528141d12b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  edit: /* #__PURE__ */ definePathHelper(
    'get',
    '(/:language-:region)/manage/subscriptions/:subscription_uuid/:token/payment_method/edit',
  ),
  show: /* #__PURE__ */ definePathHelper(
    'get',
    '(/:language-:region)/manage/subscriptions/:subscription_uuid/:token/payment_method',
  ),
  update: /* #__PURE__ */ definePathHelper(
    'patch',
    '(/:language-:region)/manage/subscriptions/:subscription_uuid/:token/payment_method',
  ),
}
