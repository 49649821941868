<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import { Accordion } from '@/components'

  const questions = [
    {
      question: 'Will FIXD Work With My Car?',
      answer:
        'FIXD works on all gas and hybrid-powered cars built in 1996 and after, and every diesel vehicle built in 2009 and after. FIXD also runs on all gas-powered trucks that were purchased in the US and have a gross vehicle weight under 14,500 lbs.',
      contact: false,
    },
    {
      question: 'Where is this port in my car?',
      answer:
        'In the vast majority of cars, it is on the driver side under the dashboard, just above the pedals. If you have difficulty finding the port in your car, contact us at',
      contact: true,
    },
    {
      question: 'Can I leave FIXD in my car all the time?',
      answer:
        'You can leave the sensor plugged in all the time, as it uses very little power when not communicating with the app. If you leave your car sitting for more than 3 weeks, we recommend that you remove the sensor.',
      contact: false,
    },
    {
      question:
        'My family has vehicles all over the country. Do I have to be near a vehicle to use FIXD?',
      answer:
        'As long as there is a person with the car running the FIXD App, you can see the status and details of that car through our Multi-Vehicle Control feature.',
      contact: false,
    },
    {
      question: 'How Does FIXD Connect To My Phone?',
      answer:
        'FIXD syncs data wirelessly and automatically to your smartphone through bluetooth when in your vehicle.',
      contact: false,
    },
    {
      question:
        'Will the sensor interfere with other Bluetooth devices in my car?',
      answer:
        'No, your smartphone will allow you to pair and communicate with multiple Bluetooth receivers at one time.',
      contact: false,
    },
    {
      question: 'Does FIXD Work With Windows Phones?',
      answer:
        'There currently is no plan for Windows Phone, but if you contact us, we will keep you updated.',
      contact: false,
    },
    {
      question: 'Does The FIXD Device Need To Be Charged?',
      answer:
        'No. The FIXD device uses vehicle energy to stay charged and uses at a low-energy mode, so it won’t drain your battery.',
      contact: false,
    },
    {
      question: 'Is There A Money-Back Guarantee?',
      answer:
        'Yes. Every device sold comes with a 30-Day Money Back Guarantee.',
      contact: false,
    },
    {
      question:
        'Will I have to pay any additional customs, duties or VAT fees when I receive my order?',
      answer:
        'Depending on your country, it is possible that you will be charged one or more of these fees when you receive your order.',
      contact: false,
    },
    {
      question: 'Is There A Warranty?',
      answer: 'Yes. Every device sold comes with 1 year limited warranty.',
      contact: false,
    },
    {
      question: 'How long does delivery take to countries outside of the U.S.?',
      answer:
        'We ship orders out FAST, but we can’t control carrier transit times. For orders outside the USA, please allow 7-12 business days for your order to arrive. Delivery times will vary based on country.',
      contact: false,
    },
  ]
</script>

<div class="bg-[#beb3a2]">
  <Section center={true} verticalPadding="short" horizontalPadding="none">
    <Accordion open={false} noChevron={true} fullWidthHeader={false}>
      <div slot="header">
        <h2 class="text-36 text-black-0">
          Have a Question? <span class="text-white font-normal underline"
            >See Our FAQs</span
          >
        </h2>
      </div>
      <div slot="body" class="mt-3">
        {#each questions as question}
          <div class="rounded-lg bg-white p-4 mb-2.5 text-lg">
            <div class="mb-4">
              <p class="font-bold">{question.question}</p>
            </div>

            <p class="mb-2.5">
              <span class="font-bold">A: </span>{question.answer}
              {#if question.contact}
                <a
                  href="mailto:fixd-contactus@fixdapp.com"
                  class="text-black-0 font-bold"
                >
                  fixd-contactus@fixdapp.com
                </a>
              {/if}
            </p>
          </div>
        {/each}
      </div>
    </Accordion>
  </Section>
</div>
