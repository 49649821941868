import { createHandler } from '@/util/events.js'

// TODO: Somehow include this automatically when an accordion
// component is used.
createHandler('toggleAccordion', function (ev) {
  ev.preventDefault()

  this.classList.toggle('rotate-180')

  const container = this.closest('.accordion-container')
  const body = container.querySelector('.accordion-body')
  body.classList.toggle('max-h-0')
  body.classList.toggle('max-h-[3000px]')
})
