<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import BusinessInsiderImage from '@/images/landing-page/webflow/business-insider-simple.png'
  import FoxImage from '@/images/landing-page/webflow/fox-simple.png'
  import ForbesImage from '@/images/landing-page/forbes-simple.png'

  import CnetImage from '@/images/landing-page/webflow/cnet-simple.png'

  const images = [
    {
      uri: BusinessInsiderImage,
      alt: 'business insider',
      link: 'https://www.businessinsider.com/guides/tech/fixd-car-sensor-review-tells-you-what-check-engine-light-means-2018-3',
    },
    {
      uri: FoxImage,
      alt: 'business insider',
      link: 'https://www.fox13now.com/news/technology/fixd-device-and-app-help-you-figure-out-whats-wrong-with-your-car?adb_sid=09245f23-2f34-461e-9ab8-f4a92c72c486',
    },
    {
      uri: ForbesImage,
      alt: 'business insider',
      link: 'https://www.forbes.com/sites/jaimecatmull/2019/12/17/7-last-minute-gifts-that-are-worth-your-money/?sh=7b53c6451e24&adb_sid=09245f23-2f34-461e-9ab8-f4a92c72c486',
    },
    {
      uri: CnetImage,
      alt: 'business insider',
      link: 'https://www.cnet.com/roadshow/news/save-67-on-a-car-gadget-that-diagnoses-error-codes-translates-mechanic-to-english/?adb_sid=09245f23-2f34-461e-9ab8-f4a92c72c486',
    },
  ]
</script>

<style>
  .gradient-background {
    background-color: #f6f4f0;
    background-image: linear-gradient(#fff, #e3e1db);
  }
</style>

<div class="gradient-background">
  <Section center="true" verticalPadding="short">
    <div class="md:max-w-[740px] lg:max-w-none mx-auto">
      <div class="xs:flex flex-row items-center">
        <div class="flex-1 xs:text-left xs:px-2.5">
          <div class="mb-2.5">
            <h2
              class="text-4xl xs:text-36 capitalize font-bold font-montserrat"
            >
              Prevent $1000s In Unnecessary Car Repairs
            </h2>
          </div>

          <p class="mb-4 xs:mb-8 text-sm xs:text-lg">
            FIXD instantly tells you why your ‘Check Engine Light’ is on, how
            severe it is, and how much the repair should cost – all in simple
            and easy-to-understand terms (so you never get ripped off by a
            mechanic again!)
          </p>

          <p class="font-bold text-sm xs:text-lg">
            ‍FIXD works with all gas-powered cars and trucks built after 1996
            and diesels built after 2008.
          </p>

          <slot name="cta" />

          <div class="mt-4 flex flex-col xs:flex-row items-center">
            <div class="shrink-0 mb-4 xs:mb-0 xs:mx-4">
              <h4 class="uppercase font-black opacity-50">as seen on:</h4>
            </div>
            <div class="flex gap-0 xs:gap-4">
              {#each images as image}
                <a href={image.link} target="_blank" rel="noreferrer">
                  <img src={image.uri} alt={image.alt} />
                </a>
              {/each}
            </div>
          </div>
        </div>
        <div class="flex-1 mt-12 sm:mt-0 sm:ml-2 xs:px-2.5">
          <div class="relative pb-[56.25%]">
            <iframe
              class="absolute top-0 left-0 w-full h-full"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/6mohVjTQ0Pw?wmode=opaque&widget_referrer=https%3A%2F%2Fwww.fixd.com%2F&enablejsapi=1&origin=https%3A%2F%2Fcdn.embedly.com&widgetid=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </div>
  </Section>
</div>
