// JsFromRoutes CacheKey e892db3b37abe09329ab66a007bff4fc
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  new: /* #__PURE__ */ definePathHelper(
    'get',
    '(/:language-:region)/buy/:funnel_slug/:offer_slug',
  ),
  checkout: /* #__PURE__ */ definePathHelper(
    'get',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id',
  ),
  placeOrder: /* #__PURE__ */ definePathHelper(
    'put',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id',
  ),
  update: /* #__PURE__ */ definePathHelper(
    'put',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/update',
  ),
  updateItems: /* #__PURE__ */ definePathHelper(
    'post',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/update/items',
  ),
  orderSummaryRemoveItem: /* #__PURE__ */ definePathHelper(
    'post',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/order_summary/remove_item/:item_id',
  ),
  orderSummaryAddPremiumBack: /* #__PURE__ */ definePathHelper(
    'post',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/order_summary/add_premium_back',
  ),
  orderSummaryAddDiscount: /* #__PURE__ */ definePathHelper(
    'post',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/order_summary/add_discount',
  ),
  formPersist: /* #__PURE__ */ definePathHelper(
    'put',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/form/persist',
  ),
  postPurchaseOffer: /* #__PURE__ */ definePathHelper(
    'get',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id',
  ),
  acceptPostPurchaseOffer: /* #__PURE__ */ definePathHelper(
    'post',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id/accept',
  ),
  rejectPostPurchaseOffer: /* #__PURE__ */ definePathHelper(
    'post',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id/reject',
  ),
  mimicTracking: /* #__PURE__ */ definePathHelper(
    'post',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id/accept_offer',
  ),
  rejectPostPurchaseOfferWithTracking: /* #__PURE__ */ definePathHelper(
    'post',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id/reject_offer',
  ),
  loading: /* #__PURE__ */ definePathHelper(
    'get',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/loading',
  ),
  loadingStatus: /* #__PURE__ */ definePathHelper(
    'get',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/loading_status',
  ),
  confirmation: /* #__PURE__ */ definePathHelper(
    'get',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/complete',
  ),
  confirmationUpdate: /* #__PURE__ */ definePathHelper(
    'put',
    '(/:language-:region)/secure/checkout/:funnel_slug/:checkout_id/complete/update',
  ),
}
