// JsFromRoutes CacheKey 378e0c7be29fcfe8aa5a5bab1286db9e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  create: /* #__PURE__ */ definePathHelper(
    'post',
    '(/:language-:region)/survey',
  ),
}
