<script>
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import Banner from '@/components/landing-page/Banner.svelte'
  import BuyBoxL007 from '@/pages//scorpio/components/L007/BuyBoxL007.svelte'

  import Compatibility from '@/components/landing-page/Compatibility.svelte'
  import LpDiagnose from '@/components/bfcm/LPDiagnose.svelte'
  import LPMoreThanCodeReader from '@/components/bfcm/LPMoreThanCodeReader.svelte'
  import AsSeenAsOn from '@/components/bfcm/AsSeenAsOn.svelte'
  import LPStocking from '@/components/bfcm/LPStocking.svelte'
  import LPRevolutionizing from '@/components/bfcm/LPRevolutionizing.svelte'
  import LPFaq from '@/components/bfcm/LPFaq.svelte'
  import ReviewYoutubeLinks from '@/components/landing-page/widgets/ReviewYoutubeLinks.svelte'
  import StaticReviews from '@/components/landing-page/widgets/StaticReviews.svelte'

  export let funnel
  export let gateway

  const buyUrl = `/buy/${funnel?.slug}` || '/buy/sensor67'
</script>

<style>
  .buy-box-background {
    background-image: linear-gradient(135deg, #274161, #000);
  }
</style>

<main class="text-black relative">
  <div class="h-[84px] md:h-[90px]">
    <div class="fixed top-0 bg-white w-full z-20">
      <div class="banner bg-green font-montserrat py-1">
        <p class="text-white my-0 text-xs font-semibold text-center md:text-sm">
          Order now to get <span class="font-extrabold">FREE</span> shipping
        </p>
      </div>
      <div class="py-3.5">
        <Banner darkTheme={false} height="short" {buyUrl} />
      </div>
    </div>
  </div>

  <div class="buy-box-background pb-10 pt-5">
    <Section
      center="true"
      maxWidth="2xl"
      verticalPadding="short"
      horizontalPadding="none"
    >
      <div class="mb-5 font-monserrat">
        <h1 class="text-green text-38 md:text-45 font-extrabold">
          BLACK FRIDAY SALE
        </h1>
        <h2 class="text-white pt-2.5">
          <span class="strikethrough">$59</span> Only
          <span class="text-green">$19.99</span> for a limited time!
        </h2>
      </div>

      <div class="bg-white p-[2%] rounded-3xl">
        <BuyBoxL007 {funnel} {gateway} />
      </div>
    </Section>
  </div>

  <AsSeenAsOn />
  <LpDiagnose />
  <LPMoreThanCodeReader />
  <LPStocking />
  <Compatibility />

  <div class="max-w-[940px] mx-auto">
    <div class="text-center">
      <h3 class="text-xl font-normal mb-2" id="live_reviews">
        Don't Just Take Our Word For It...
      </h3>
      <h1 class="font-semibold md:font-bold text-30 mt-0">
        What Are Other Drivers Saying about FIXD?
      </h1>
    </div>
    <div class="my-4"></div>
    <ReviewYoutubeLinks />

    <StaticReviews />
  </div>

  <LPRevolutionizing />
  <div class="pb-[5%]">
    <LPFaq />
  </div>

  <Footer />
</main>
