import { createHandler } from '@/util/events.js'
import '@/components/accordion.js'

createHandler('toggleLocaleMenu', function (ev) {
  ev.preventDefault()
  const menu = document.querySelector('.i18n-picker')

  menu.classList.toggle('hidden')
})

createHandler('toggleLanguageMenu', function (ev) {
  ev.preventDefault()
  const menu = document.querySelector('.language.menu')
  menu.classList.toggle('hidden')
})

window.addEventListener('load', (event) => {
  document.querySelector('.initial-hidden')?.classList?.remove('initial-hidden')
})

createHandler('toggleRegion', function (ev) {
  ev.preventDefault()

  Array.from(document.querySelectorAll('.region-grouping')).forEach((x) => {
    if (x !== this.parentNode) {
      x.classList.remove('open')
    }
  })
  this.parentNode.classList.toggle('open')
})
